
import { defineComponent } from "vue";
import { convertMinuteToHHMM } from "@/core/helpers/common";

export default defineComponent({
  name: "top-indicator",
  components: {},
  props: {
    stats: Object,
  },
  setup(props) {
    const topTimeUsingApp = props.stats?.topTimeUsingApp as {
      people_id: number;
      sum_app_time: string;
      sum_open_times: string;
      first_name: string;
      last_name: string;
      avatar: string;
    }[];

    const topZenTime = props.stats?.topZenTime as {
      id: number;
      people_id: number;
      sum_min: number;
      level: number;
      // "last_activity": "{\"timeAt\":1649251388,\"zenName\":\"Bai thien so 10\",\"zenTime\":500}",
      last_activity: string;
      first_name: string;
      last_name: string;
      avatar: string;
      type: string;
    }[];

    const topTimeByZenMedia = props.stats?.topTimeByZenMedia as {
      id: number;
      static_room_id: number;
      media_id: string;
      type: string;
      zen_name: string;
      open_times: number;
      sum_min: number;
    }[];

    const topTimeZenMediaGroupByStaticRoom = props.stats
      ?.topTimeZenMediaGroupByStaticRoom as {
      static_room_id: number;
      static_room_name: string;
      total_mins: number;
      total_times: string;
    }[];

    return {
      topZenTime,
      topTimeUsingApp,
      topTimeByZenMedia,
      topTimeZenMediaGroupByStaticRoom,
      convertMinuteToHHMM,
    };
  },
});
