
import { convertMinuteToHHMM, numberWithCommas } from "@/core/helpers/common";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "top-indicator",
  components: {},
  props: {
    stats: Object,
  },
  setup(props) {
    const accounts = props.stats?.account.allTime as {
      type: string;
      count_by_type: string;
    }[];

    const numberAccount = ref(0);
    const numberStudent = ref(0);
    const numberGuest = ref(0);
    const numberTeacher = ref(0);
    const averageTimeUser = ref("");
    const sumAppTime = ref("");
    const sumOpenTimes = ref("");

    accounts.map((m) => {
      numberAccount.value += parseInt(m.count_by_type);
    });

    numberStudent.value = parseInt(
      accounts.filter((m) => m.type == "student")?.[0]?.count_by_type
    );

    numberTeacher.value = parseInt(
      accounts.filter((m) => m.type == "teacher")?.[0]?.count_by_type
    );

    numberGuest.value = parseInt(
      accounts.filter((m) => m.type == "guest")?.[0]?.count_by_type
    );

    const systemTimeUsingApp = props.stats?.systemTimeUsingApp as any;

    averageTimeUser.value = convertMinuteToHHMM(
      Math.floor(systemTimeUsingApp.average_time_per_people.value / 60)
    );
    sumAppTime.value =
      numberWithCommas(
        Math.floor(parseInt(systemTimeUsingApp.sum_app_time.value) / 60)
      ) + " giờ";

    sumOpenTimes.value =
      numberWithCommas(systemTimeUsingApp.sum_open_times.value) + " lần";

    return {
      numberAccount,
      numberStudent,
      numberTeacher,
      numberGuest,
      averageTimeUser,
      sumAppTime,
      sumOpenTimes,
      numberWithCommas,
    };
  },
});
