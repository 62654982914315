
import { defineComponent } from "vue";

export default defineComponent({
  name: "number-user-period",
  props: {
    stats: Object,
  },
  components: {},
  setup(props) {
    // const labelColor = getCSSVariableValue("--bs-gray-500");
    // const borderColor = getCSSVariableValue("--bs-gray-200");
    // const baseColor = getCSSVariableValue("--bs-warning");
    // const secondaryColor = getCSSVariableValue("--bs-gray-300");

    const allUserPeriod = props.stats?.account.byPeriod.allPeopleType as {
      cnt: string;
      yy: number;
      mm: number;
    }[];

    const studentPeriod = props.stats?.account.byPeriod.byPeopleType.filter(
      (m) => m.type === "student"
    )?.[0].periodByType as {
      cnt: string;
      yy: number;
      mm: number;
    }[];

    const minMonth = 1;
    const maxMonth = new Date().getMonth() + 1;

    const months: string[] = [];
    const numberUser: number[] = [];
    const numberStudent: number[] = [];

    for (let index = minMonth; index <= maxMonth; index++) {
      months.push("Tháng " + index.toString());
      let hasValue = false;
      allUserPeriod.forEach((item) => {
        if (item.mm === index) {
          numberUser.push(parseInt(item.cnt));
          hasValue = true;
          return false;
        }
      });
      if (!hasValue) {
        numberUser.push(0);
      }

      // ===================

      hasValue = false;
      studentPeriod.forEach((item) => {
        if (item.mm === index) {
          numberStudent.push(parseInt(item.cnt));
          hasValue = true;
          return false;
        }
      });
      if (!hasValue) {
        numberStudent.push(0);
      }
    }

    const options = {
      chart: {
        height: 450,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Tài khoản",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: months,
      },
    };

    const series = [
      {
        name: "Tất cả",
        data: numberUser,
      },
      {
        name: "Học viên",
        data: numberStudent,
      },
    ];
    return {
      options,
      series,
    };
  },
});
